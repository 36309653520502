export default {
    'problem_feedback.header_title': '问题反馈',
    'problem_feedback.sub_title_1': '用户ID',
    'problem_feedback.sub_title_2': '问题类型',
    'problem_feedback.sub_title_3': '问题详情',
    'problem_feedback.sub_title_4': '问题图片',
    'problem_feedback.sub_title_5': '手机号码（非必填)',

    'problem_feedback.question_type_1': '登录问题',
    'problem_feedback.question_type_2': '支付问题',
    'problem_feedback.question_type_3': '游戏问题',
    'problem_feedback.question_type_4': '其他问题',

    'problem_feedback.sub_title_1_tips': '请选择问题类型',
    'problem_feedback.sub_title_2_tips': '请填写问题描述',
    'problem_feedback.sub_title_3_tips': '请输入你遇到的具体问题',
    'problem_feedback.sub_title_4_tips': '如果你面临的是支付问题，请上传对应支付截图，以保证我们能够更快处理你的问题',
    'problem_feedback.sub_title_5_tips': '请提供你的电话号码，以便遇到重要问题时我们能够联系到你',

    'problem_feedback.popup_title': '提示',
    'problem_feedback.popup_content': '谢谢你的反馈！我们会尽快处理您遇到的问题！如果您的反馈被认为是有效的，你将会收到对应的金币奖励。请注意查看邮件消息',
    'problem_feedback.determine': '确定',

    'problem_feedback.uploading': '上传中',
    'problem_feedback.failed': '失败',
    'problem_feedback.least': '至少上传一张图片',
    'problem_feedback.not_supported': '不支持该图片格式',
};