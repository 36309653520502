export default {
    'problem_feedback.header_title': 'Question Feedback',
    'problem_feedback.sub_title_1': 'User ID',
    'problem_feedback.sub_title_2': 'Question Types',
    'problem_feedback.sub_title_3': 'Question Detail',
    'problem_feedback.sub_title_4': 'Question Picture',
    'problem_feedback.sub_title_5': 'Phone Number (Unnecessary)',

    'problem_feedback.question_type_1': 'Login',
    'problem_feedback.question_type_2': 'Payment',
    'problem_feedback.question_type_3': 'Game',
    'problem_feedback.question_type_4': 'Other',

    'problem_feedback.sub_title_1_tips': 'Please select the type of issue',
    'problem_feedback.sub_title_2_tips': 'Please provide a description of the issue',
    'problem_feedback.sub_title_3_tips': 'Please enter your question here!',
    'problem_feedback.sub_title_4_tips': 'If you are experiencing payment issues, please upload a screenshot of the payment for faster problem resolution',
    'problem_feedback.sub_title_5_tips': 'Please provide your phone number so that we can contact you promptly for important issues',

    'problem_feedback.popup_title': 'NOTICE',
    'problem_feedback.popup_content': 'Thank you for your feedback! We will address the issues you encountered as soon as possible. If your feedback proves to be effective, you may receive a certain amount of gold coin rewards. Please check your email for updates',
    'problem_feedback.determine': 'Confirmed',

    'problem_feedback.uploading': 'Uploading',
    'problem_feedback.failed': 'Failed',
    'problem_feedback.least': 'Please upload at least one image',
    'problem_feedback.not_supported': 'Unsupported image',
};