export default {
    'problem_feedback.header_title': 'การแจ้งปัญหา',
    'problem_feedback.sub_title_1': 'ไอดีผู้ใช้',
    'problem_feedback.sub_title_2': 'ประเภทของปัญหา',
    'problem_feedback.sub_title_3': 'รายละเอียดของปัญหา',
    'problem_feedback.sub_title_4': 'ภาพของปัญหา',
    'problem_feedback.sub_title_5': 'เบอร์โทรศัพท์ (ไม่บังคับ)',

    'problem_feedback.question_type_1': 'เข้าสู่ระบบ',
    'problem_feedback.question_type_2': 'การชำระเงิน',
    'problem_feedback.question_type_3': 'เกม',
    'problem_feedback.question_type_4': 'อื่น ๆ',

    'problem_feedback.sub_title_1_tips': 'โปรดเลือกประเภทของปัญหา',
    'problem_feedback.sub_title_2_tips': 'กรุณากรอกรายละเอียดของปัญหา',
    'problem_feedback.sub_title_3_tips': 'โปรดระบุปัญหาที่คุณพบ',
    'problem_feedback.sub_title_4_tips': 'หากคุณพบปัญหาในการชำระเงิน กรุณาอัปโหลดภาพหน้าจอการชำระเงินที่เกี่ยวข้องเพื่อให้เราสามารถแก้ไขปัญหาของคุณได้เร็วขึ้น',
    'problem_feedback.sub_title_5_tips': 'โปรดระบุหมายเลขโทรศัพท์ของคุณ เพื่อให้เราสามารถติดต่อกับคุณเมื่อเกิดปัญหาสำคัญ',

    'problem_feedback.popup_title': 'คำแนะนำ',
    'problem_feedback.popup_content': 'ขอบคุณสำหรับคำติชมของคุณ! เราจะดำเนินการประมวลผลปัญหาที่คุณพบโดยเร็วที่สุด! หากการตอบรับของคุณถูกพิจารณาว่าถูกต้อง คุณจะได้รับรางวัลเหรียญทองที่เหมาะสม โปรดทบทวนข้อความทางอีเมลอย่างใจความ',
    'problem_feedback.determine': 'ยืนยัน',

    'problem_feedback.uploading': 'กำลังอัปโหลด',
    'problem_feedback.failed': 'ล้มเหลว',
    'problem_feedback.least': 'กรุณาอัปโหลดรูปภาพอย่างน้อยหนึ่งรูป',
    'problem_feedback.not_supported': 'ไม่รองรับรูปแบบของภาพนี้',
};