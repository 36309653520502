import { ref } from "@vue/reactivity";
import { getJsUrl } from '@/utils/function';
import { createI18n } from 'vue-i18n';
import cn from './cn';
import en from './en';
import vn from './vn';
import pt from './pt';
import id from './id';
import th from './th';

var route:any;

if(location.href.indexOf('?') !== -1){
    route = getJsUrl(location.href);
}

if(route && route.language){
    sessionStorage.setItem('locale-language',route.language);
}else{
    const language = sessionStorage.getItem('locale-language');
    language ? sessionStorage.setItem('locale-language',language) : sessionStorage.setItem('locale-language','en');
}



export const LOCALE_OPTIONS = [
    { label: '越南', value: 'vn' },
    { label: '意大利', value: 'it' },
    { label: '日本', value: 'ja' },
    { label: '英国', value: 'en' },
    { label: '美国', value: 'en' },
    { label: '巴西', value: 'pt' },
    { label: '印度尼西亚', value: 'id' },
    { label: '印度', value: 'hi' },
    { label: '印尼', value: 'id' },
    { label: '马来西亚', value: 'ms' },
    { label: '菲律宾', value: 'en' },
    { label: '泰国', value: 'th' },
    { label: '俄罗斯', value: 'ru' },
    { label: '墨西哥', value: 'en' },
    { label: '哥伦比亚', value: 'en' },
    { label: '阿根廷', value: 'en' },
    { label: '土耳其', value: 'en' },
    { label: '尼日利亚', value: 'en' },
    { label: '南非', value: 'en' },
    { label: '肯尼亚', value: 'en' },
    { label: '中文', value: 'en' },
];

const defaultLocale = ref(sessionStorage.getItem('locale-language') ? sessionStorage.getItem('locale-language') : route.language ? route.language : 'en');

const i18n = createI18n({
    locale: defaultLocale.value,
    fallbackLocale: 'en',
    allowComposition: true,
    messages: {
        cn,
        en,
        vn,
        pt,
        id,
        th
    }
  });
  
  export {
    defaultLocale,
    i18n
  };