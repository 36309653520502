export default {
    namespaced:true,
    state: {
        KYCinfo:{
          editId:'',
          mode:'',
          country:'',
          realName:'',
          idType:'',
          idNumber:'',
          facePhoto:'',
          backPhoto:'',
          handheldPhoto:'',
          headPhoto:''
        }
    },
    mutations: {
      onUpdateKYCinfo(state:any,value:any){
        state.KYCinfo[value.field] = value.value;
      },
      onResetKYCinfo(state:any){
        state.KYCinfo = {
          country:'',
          realName:'',
          idType:'',
          idNumber:'',
          facePhoto:'',
          backPhoto:'',
          handheldPhoto:'',
          headPhoto:''
        };
      }
    },
}