import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { i18n } from '@/locale/index';
import { Toast } from 'vant';



const { t } = i18n.global;


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/problem-feedback',
    name: 'problem-feedback',
    component: () => import('../views/problem-feedback/index.vue')
  },
  {
    path: '/problem-feedback-h',
    name: 'problem-feedback-h',
    component: () => import('../views/problem-feedback/horizontal.vue')
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import('../views/upload/index.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/notFound/notfound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name:'notfound',
    redirect:'/404'
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach(async (to:any, from:any) => {
 
})

export default router
