import { getUploadUrl , uploadResource } from '@/api/user';
import { Toast } from 'vant';
import axios from 'axios'; 



// URL地址解析
export function getJsUrl(url:any):any{
	var pos,str,para,parastr; 
	var array:any =[]
	str = location.href; 
	parastr = str.split("?")[1]; 
	var arr = parastr.split("&");
	for (var i=0;i<arr.length;i++){
        array[arr[i].split("=")[0]]=arr[i].split("=")[1];
    }
    return array;
}

// 复制
export function copy(data:any) {
    let OrderNumber = data;
    let newInput = document.createElement("input");
    newInput.value = OrderNumber;
    document.body.appendChild(newInput);
    newInput.select();
    document.execCommand("Copy");
    newInput.remove();
}

// Base64转文件流
export function base64toFile(urlString:any, fileName:any) {
    const dataArr = urlString.split(",");
    const byteString = atob(dataArr[1]);
    const options:any = {
      type: "image/png",
      endings: "native"
    };
    const u8Arr = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      u8Arr[i] = byteString.charCodeAt(i);
    }
    return new File([u8Arr], fileName + ".png", options);//返回文件流
}

// 时间戳转义当地时间
export function onGetLocalTime(date:number){
  var time = new Date(date).toLocaleString();
  return time.slice(0,9);
}

// 获取资源上传地址 正常
export const upload = async (file:any,supportMsg:any,loadMsg:any) => {
  let extend = file.type.split('/')[1];
  let formatArr = ['jpg','jpeg','png','mp4'];
  if(formatArr.includes(extend) === false){
      Toast(supportMsg);
      return;
  }
  Toast.loading({
    duration: 0,
    message: loadMsg,
    forbidClick: true,
  });
  const info = {
      project:'midgame',
      prefix:'product',
      extend
  }
  const url = await getUploadUrl(info).then(res=>{
      console.log('获取资源上传地址',res);
      return res.data.sign_url;
  })
  await uploadResource(url,file).then(res=>{
      console.log('资源上传成功',res);
  })
  Toast.clear();
  return url.split('?')[0].replace('https://mili-osstore-pub-ws.s3.ap-southeast-1.amazonaws.com','https://wscdn.ads1ddcc.com');
}

// 获取资源上传地址 无loading
export const uploadNoLoading = async (file:any,supportMsg:any) => {
  let extend = file.type.split('/')[1];
  let formatArr = ['jpg','jpeg','png','mp4'];
  if(formatArr.includes(extend) === false){
      Toast(supportMsg);
      return;
  }
  const info = {
      project:'midgame',
      prefix:'product',
      extend
  }
  const url = await getUploadUrl(info).then(res=>{
      console.log('获取资源上传地址',res);
      return res.data.sign_url;
  })
  await uploadResource(url,file).then(res=>{
      console.log('资源上传成功',res);
  })
  return url.split('?')[0].replace('https://mili-osstore-pub-ws.s3.ap-southeast-1.amazonaws.com','https://wscdn.ads1ddcc.com');
}

// 获取资源上传地址 进度条
export const uploadProgress = async (file:any,onUploadProgress:any,supportMsg:any,loadMsg:any) => {
  let extend = file.type.split('/')[1];
  let formatArr = ['jpg','jpeg','png','mp4'];
  if(formatArr.includes(extend) === false){
      Toast(supportMsg);
      return;
  }
  Toast.loading({
    duration: 0,
    message: loadMsg,
    forbidClick: true,
  });
  const info = {
      project:'midgame',
      prefix:'product',
      extend
  }
  const url = await getUploadUrl(info).then(res=>{
      console.log('获取资源上传地址',res);
      return res.data.sign_url;
  })
  await axios({
    method: 'put',
    url: url,
    data:file,
    onUploadProgress
  }).then(res=>{
    console.log('资源上传成功',res);
  })
  Toast.clear();
  return url.split('?')[0].replace('https://mili-osstore-pub-ws.s3.ap-southeast-1.amazonaws.com','https://wscdn.ads1ddcc.com');
}

// 获取资源上传地址 无loading 可传指定地区
export const uploadNoLoadingCountry = async (file:any,supportMsg:any,country:any) => {
  let extend = file.type.split('/')[1];
  let formatArr = ['jpg','jpeg','png','mp4'];
  if(formatArr.includes(extend) === false){
      Toast(supportMsg);
      return;
  }
  const info = {
      project:'midgame',
      prefix:country,
      extend
  }
  const url = await getUploadUrl(info).then(res=>{
      console.log('获取资源上传地址',res);
      return res.data.sign_url;
  })
  await uploadResource(url,file).then(res=>{
      console.log('资源上传成功',res);
  })
  return url.split('?')[0].replace('https://mili-osstore-pub-ws.s3.ap-southeast-1.amazonaws.com','https://wscdn.ads1ddcc.com');
}



// 判断手机系统
export const getPlatform = () => {
  const userAgent = navigator.userAgent;
  if (/android/i.test(userAgent)) {
    return'Android';
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    return'IOS';
  } else {
    return'Unknown';
  }
};

// bse64转file
export const base64ToFile = (base64String:any) => {
 // 提取 base64 数据和 MIME 类型
 const [prefix, base64Data] = base64String.split(',');
 const mimeType = prefix.match(/:(.*?);/)[1];

 // 将 base64 字符串转换为二进制数据
 const byteString = atob(base64Data);

 // 创建一个 ArrayBuffer
 const ab = new ArrayBuffer(byteString.length);
 const ia = new Uint8Array(ab);

 // 将二进制数据存入 ArrayBuffer 中
 for (let i = 0; i < byteString.length; i++) {
   ia[i] = byteString.charCodeAt(i);
 }

 // 创建 Blob 对象
 const blob = new Blob([ab], { type: mimeType });

  // 创建 File 对象并返回
  return new File([blob], 'filename', { type: mimeType });
}