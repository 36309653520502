export default {
    'problem_feedback.header_title': 'Phản hồi vấn đề',
    'problem_feedback.sub_title_1': 'ID Game',
    'problem_feedback.sub_title_2': 'Loại vấn đề',
    'problem_feedback.sub_title_3': 'Chi tiết vấn đề',
    'problem_feedback.sub_title_4': 'Ảnh chụp màn hình',
    'problem_feedback.sub_title_5': 'Số điện thoại hoặt số telegram(tùy chọn)',

    'problem_feedback.question_type_1': 'Đăng nhập',
    'problem_feedback.question_type_2': 'Thanh toán',
    'problem_feedback.question_type_3': 'Trò chơi',
    'problem_feedback.question_type_4': 'Vấn đề khác',

    'problem_feedback.sub_title_1_tips': 'Hãy chọn loại câu hỏi',
    'problem_feedback.sub_title_2_tips': 'Hãy điền mô tả vấn đề',
    'problem_feedback.sub_title_3_tips': 'Hãy nhập vấn đề cụ thể bạn gặp phải!',
    'problem_feedback.sub_title_4_tips': 'Nếu bạn nạp tiền chưa lên điểm , hãy cung cấp ảnh thanh toán thành công (bao gồm thời gian cụ thể, số tiền, lời nhắn,thông tin người  nhận tiền)',
    'problem_feedback.sub_title_5_tips': 'Vui lòng cung cấp sđt hoặc số telegram của bạn để chúng tôi có thể liên hệ với bạn nếu có câu hỏi quan trọng',

    'problem_feedback.popup_title': 'Lưu ý',
    'problem_feedback.popup_content': 'Cảm ơn phản hôi của bạn! Chúng tôi sẽ giải quyết những vấn đề bạn gặp phải trong thời gian sớm nhất! Nếu phản hồi của bạn được coi là hợp lệ, bạn sẽ nhận được phần thưởng tiền vàng tương ứng. Vui lòng kiểm tra tin nhắn email của bạn',
    'problem_feedback.determine': 'Xác nhận',

    'problem_feedback.uploading': 'Xác nhận',
    'problem_feedback.failed': 'Đang tải',
    'problem_feedback.least': 'Vui lòng tải lên ít nhất một hình ảnh',
    'problem_feedback.not_supported': 'Không hỗ trợ định dạng hình ảnh này',
};