export default {
    'problem_feedback.header_title': 'Umpan Balik Pertanyaan',
    'problem_feedback.sub_title_1': 'ID Pengguna',
    'problem_feedback.sub_title_2': 'Jenis Pertanyaan',
    'problem_feedback.sub_title_3': 'Detail Pertanyaan',
    'problem_feedback.sub_title_4': 'Gambar Pertanyaan',
    'problem_feedback.sub_title_5': 'Nomor Telepon (Tidak Diperlukan)',

    'problem_feedback.question_type_1': 'Login',
    'problem_feedback.question_type_2': 'Pembayaran',
    'problem_feedback.question_type_3': 'Game',
    'problem_feedback.question_type_4': 'Lainnya',

    'problem_feedback.sub_title_1_tips': 'Silakan pilih jenis masalah',
    'problem_feedback.sub_title_2_tips': 'Silakan berikan deskripsi masalah',
    'problem_feedback.sub_title_3_tips': 'Silakan masukkan pertanyaan Anda di sini!',
    'problem_feedback.sub_title_4_tips': 'Jika Anda mengalami masalah pembayaran, harap unggah tangkapan layar pembayaran untuk pemecahan masalah yang lebih cepat',
    'problem_feedback.sub_title_5_tips': 'Harap berikan nomor telepon Anda agar kami dapat menghubungi Anda dengan cepat untuk masalah penting',

    'problem_feedback.popup_title': 'PEMBERITAHUAN',
    'problem_feedback.popup_content': 'Terima kasih atas umpan balik Anda! Kami akan menanggapi masalah yang Anda alami secepat mungkin. Jika umpan balik Anda terbukti efektif, Anda mungkin menerima sejumlah hadiah koin emas. Harap periksa email Anda untuk pembaruan',
    'problem_feedback.determine': 'Dikonfirmasi',

    'problem_feedback.uploading': 'Mengunggah',
    'problem_feedback.failed': 'Gagal',
    'problem_feedback.least': 'Harap unggah setidaknya satu gambar',
    'problem_feedback.not_supported': 'Gambar tidak didukung',
};