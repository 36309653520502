export default {
    'problem_feedback.header_title': 'Feedback de Problemas',
    'problem_feedback.sub_title_1': 'ID do Usuário',
    'problem_feedback.sub_title_2': 'Tipo Problema',
    'problem_feedback.sub_title_3': 'Detalhes do Problema',
    'problem_feedback.sub_title_4': 'Imagem do Problema',
    'problem_feedback.sub_title_5': 'Número de telefone (opcional)',

    'problem_feedback.question_type_1': 'Login',
    'problem_feedback.question_type_2': 'Pagamento',
    'problem_feedback.question_type_3': 'Jogo',
    'problem_feedback.question_type_4': 'Outros',

    'problem_feedback.sub_title_1_tips': 'Por favor, selecione o tipo de problema',
    'problem_feedback.sub_title_2_tips': 'Por favor, forneça uma descrição do problema',
    'problem_feedback.sub_title_3_tips': 'Por favor, descreva o problema que você está enfrentando!',
    'problem_feedback.sub_title_4_tips': 'Se estiveres a enfrentar problemas de pagamento, por favor, faz o envio da captura de ecrã do pagamento correspondente para assegurar que possamos resolver o teu problema mais rapidamente',
    'problem_feedback.sub_title_5_tips': 'Por favor, forneça o seu número de telefone para que possamos entrar em contato consigo em caso de questões importantes',

    'problem_feedback.popup_title': 'AVISO',
    'problem_feedback.popup_content': 'Obrigado pelo seu feedback! Abordaremos as questões que você encontrou o mais rápido possível. Se o seu feedback se mostrar eficaz, você poderá receber uma certa quantidade de recompensas em moedas de ouro. Por favor, verifique o seu email para atualizações',
    'problem_feedback.determine': 'Confirmado',

    'problem_feedback.uploading': 'Carregando',
    'problem_feedback.failed': 'Falha',
    'problem_feedback.least': 'Por favor, faça o upload de pelo menos uma imagem',
    'problem_feedback.not_supported': 'Formato de imagem não suportado',
};