import createPersistedState from 'vuex-persistedstate';
import { createStore } from 'vuex'
import KYC from './KYC'   // KYC信息

export default createStore({
  modules: {
    KYC
  },
  plugins:[
      createPersistedState({
          storage:window.localStorage
      })
  ]
})
